import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BranchesService } from '../branches/branches.service';
import { MenuService } from '../../services/menu.service';
import { Organisation } from '../../models/organisation';
import { Branch } from '../../models/branch';
import { ConnectionService } from '../connections/connections.service';
import { OrganisationsService } from '../organisations/organisations.service';
import { Invite } from '../../models/invite';
import { FlashMessageService } from '../../services/flash-message.service';

@Component({
    selector: 'app-invite-send',
    templateUrl: './invite-send.html',
    styleUrls: ['./invite-send.scss']
})
export class InviteSendPage implements OnInit, OnDestroy {

    @ViewChild('errorModal', {static : true}) errorModal: ElementRef;

    public organisationId: string = null;
    public organisation: Organisation = null;
    public branchId: string = null;
    public branch: Branch = null;
    public initiatingBranch: Branch = null;
    public inviteId: string = null;
    public token: string = null;
    private isDestroyed: Subject<void> = new Subject<void>();

    constructor(
        private branchService: BranchesService,
        private connectionService: ConnectionService,
        private flashMessageService: FlashMessageService,
        private menuService: MenuService,
        private organisationService: OrganisationsService,
        private route: ActivatedRoute,
        private router: Router,
    ) {
    }

    public ngOnInit(): void {

        this.menuService.setActive('connections');

        this.organisationService.getOrganisation().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(organisation => this.organisation = organisation);

        this.branchService.getBranch().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(branch => this.branch = branch);

        this.connectionService.getInitiatingBranch().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(branch => {
            if (branch.organisation_id !== this.organisationId) {
                this.flashMessageService.addMessage('error', 'Your cannot send this invite.');
                if (this.branch) {
                    this.router.navigate(['organisations', this.organisationId, 'branches', this.branchId]);
                } else {
                    this.router.navigate(['organisations', this.organisationId, 'connections']);
                }
            } else {
                this.initiatingBranch = branch;
            }
        });

        this.route.params.pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(params => {
            if (params.hasOwnProperty('organisation_id')) {
                this.organisationId = params.organisation_id;
                this.organisationService.fetchOrganisation(this.organisationId);

                if (params.hasOwnProperty('branch_id')) {
                    this.branchId = params.organisation_id;
                    this.branchService.fetchBranch(this.organisationId, this.branchId);
                }

                if (params.hasOwnProperty('invite_id')) {
                    this.inviteId = params.invite_id;
                    this.connectionService.fetchInvite(this.inviteId);
                }

                if (params.hasOwnProperty('token')) {
                    this.token = params.token;
                }
            }
        });
    }

    public ngOnDestroy(): void {
        this.menuService.setActive(null);
        this.isDestroyed.next();
    }

    public goToPage(url: any) {
        this.router.navigate([url]);
    }

    public async sendInvite() {
        try {
            const response = await this.connectionService.sendInvite(this.inviteId);
            if (response.success) {
                this.flashMessageService.showMessage('success', 'Your invite has been sent.');
            } else {
                throw new Error();
            }
        } catch (e) {
            this.flashMessageService.showMessage('error', 'An error occurred sending your invite.');
        }
    }

}
